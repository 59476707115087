import { ZUimages } from "assets";
import { UserImgData } from "utils/userImg";
import { getCurrentTimeMessage } from "utils/meetingsList";
import { Link } from "react-router-dom";
import UserDateTimeDisplay from "./UserDateTimeDisplay";
import { ClockIcon, moreHorizontalIcon } from "assets/icons";
import { useAppSelector } from "hooks";
import { useGetAllMeetingsQuery } from "services/meetings.service";
import env from "configs";
export interface MeetingDetails {
  meetingId: string;
  title: string;
  start: string;
  end: string;
  Status: string;
  guests: [];
}

const displayTimeMsg = (meeting: any): React.ReactElement => {
  const meetingStartTime = new Date(meeting.start);
  const currentDate = new Date();
  const displayMsg = getCurrentTimeMessage(meeting);

  return (
    <p
      className={`text-gray-200 text-base ${
        currentDate > meetingStartTime ? "text-[#FF0000]" : ""
      }`}
    >
      {displayMsg}
    </p>
  );
};
const ActionsList = () => {
  const MAX_DISPLAY_GUESTS = 4; // Maximum number of guests to display without "+X"
  const { user } = useAppSelector((state) => state.auth);
  const { data, isLoading } = useGetAllMeetingsQuery(user.user.email);
  const meetingsList = data?.DATA;
  const currentDate = new Date();
  const filteredMeetings =
    meetingsList?.filter((meeting) => {
      const meetingStartTime = new Date(meeting.start);
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - 1);
      return meetingStartTime > previousDate;
    }) ?? [];
  const joinMeeting = (meetingId: string) => {
    window.open(`${env.MEETING_BASE_URL}/${meetingId}`);
  };
  return (
    <div className="w-full">
      <div className="lg:max-w-[625px]">
        <UserDateTimeDisplay />
      </div>
      <div className="lg:overflow-scroll lg:h-[56vh]">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {filteredMeetings?.length >= 1 ? (
              filteredMeetings?.map((meeting) => (
                <div
                  key={meeting?.meetingId}
                  className="max-h-full md:h-[194px] mb-4 rounded-2xl p-8 bg-green-100 lg:max-w-[625px]"
                >
                  <div className="flex flex-col h-full justify-between">
                    <div className="flex items-center justify-between w-ful mb-5 md:mb-0">
                      <div className="flex flex-col gap-2">
                        <p className="font-bold text-[21px] text-black">{meeting?.title}</p>
                        <div className="flex gap-2 items-center">
                          <span>
                            <img src={ClockIcon} alt="Clock Icon" />
                          </span>
                          {displayTimeMsg(meeting)}
                        </div>
                      </div>
                      <label className="flex items-center justify-center h-[32px] w-[43px] bg-gradient-to-r from-[#daf0e1] to-[#cdebd7] rounded-lg">
                        <img
                          src={moreHorizontalIcon}
                          alt=""
                          className="h-[4px] w-[20.01px] object-contain"
                        />
                      </label>
                    </div>
                    <div className="flex justify-between max-w-[480px] flex-wrap">
                      <div className="flex mb-5 md:mb-0">
                        {meeting.guests.length === 0 ? (
                          <div>No Meeting Invitees</div>
                        ) : (
                          <>
                            {meeting.guests.slice(0, MAX_DISPLAY_GUESTS).map((_guestId, index) => {
                              if (index < UserImgData.length) {
                                const guest = UserImgData[index];
                                return (
                                  <img
                                    key={guest.id}
                                    src={guest.userImgUrl}
                                    className="rounded-full h-10 w-10 -mr-2"
                                    alt={`Guest ${index + 1}`}
                                  />
                                );
                              }
                              return null;
                            })}
                            {meeting.guests.length > MAX_DISPLAY_GUESTS && (
                              <div className="bg-green rounded-full h-10 w-10 flex items-center justify-center text-white">
                                +{meeting.guests.length - MAX_DISPLAY_GUESTS}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="flex flex-col justify-between">
                        <Link
                          to={""}
                          className="font-normal text-[18px] bg-green rounded-lg py-2 px-4 text-white flex gap-2 items-center justify-center"
                          onClick={() => joinMeeting(meeting.meetingId)}
                        >
                          <span>
                            <img src={ZUimages.ZuPlay} alt="Play Icon" />
                          </span>{" "}
                          Join
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 font-semibold pt-36">
                You have no meetings
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ActionsList;
