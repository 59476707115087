import { Landing } from "components";
import UnderConstructionView from "./UnderConstructionView";

export const UnderConstructionContainer = () => {
  return (
    <Landing>
      <UnderConstructionView />
    </Landing>
  );
};
