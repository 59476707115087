import { ZuEmail, ZuWhatsapp } from "components";

interface ShareInviteProps {
  meetingLink: string;
}
export const ShareInvite = ({ meetingLink }: ShareInviteProps) => {
  const emailBody = encodeURIComponent(`Hey! Join me in a meeting! \n ${meetingLink}`);
  const mailTo = `mailto:?body=${emailBody}`;

  return (
    <div className="inline-block rounded-t text-[14px] w-full">
      <div className=" bg-[#F5F5F5] p-2 rounded-t border-b border-[#EF691D] cursor-pointer">
        <a href={mailTo} className="w-full h-full flex gap-4 items-center">
          <ZuEmail className="text-[#EF691D]" />
          <p className="text-[#EF691D] tracking-wide">Share via Email</p>
        </a>
      </div>
      <div className="flex gap-4 items-center bg-[#F5F5F5] p-2 border-b border-[#EF691D] cursor-pointer">
        <ZuWhatsapp className="text-[#07AA3D]" />
        <p className="text-[#EF691D] tracking-wide">Share via Whatsapp</p>
      </div>
    </div>
  );
};
