import { ZUimages } from "assets";
import { meetingModalEnum } from "./enums";

export const meetingActions = [
  // {
  //   id: meetingModalEnum.INSTANT,
  //   icon: ZUicons.video,
  //   // task: "New Meeting",
  //   description: "Start an instant meeting",
  //   backgroundColor: "bg-[#07AA3D]",
  //   buttonSyle:
  //     "w-16 h-16 rounded-2xl border-[0.5px] border-green-400 flex items-center justify-center bg-gradient-to-r from-[#57c57b] to-[#06ab3d]",
  //   colSpan: "col-span-2 lg:col-span-1",
  //   width: "w-full",
  //   iconHeight: "h-[18.67px]",
  //   iconWidth: "w-[26.67px]"
  // },
  {
    id: meetingModalEnum.JOIN,
    icon: ZUimages.ZuPlus,
    // task: "Join Meeting",
    description: "Join via invitation link",
    backgroundColor: "bg-[#0E78F9]",
    buttonSyle:
      "w-16 h-16 rounded-2xl border-[0.5px] border-[#5ca0f3] flex items-center justify-center bg-gradient-to-r from-[#2E87F5] to-[#509cf9]",
    colSpan: "col-span-2 lg:col-span-1",
    width: "w-full",
    iconHeight: "h-6",
    iconWidth: "w-6"
  }
  // {
  //   id: meetingModalEnum.LATER,
  //   icon: ZUicons.calendarplus,
  //   // task: "Schedule for Later",
  //   description: "Schedule a meeting",
  //   backgroundColor: "bg-[#005C39]",
  //   buttonSyle:
  //     "w-16 h-16 rounded-2xl border-[0.5px] border-[#5c9680] flex items-center justify-center bg-gradient-to-r from-[#659d87] to-[#217052]",
  //   colSpan: "col-span-2",
  //   width: "w-full",
  //   iconHeight: "h-[27.67px]",
  //   iconWidth: "w-[26.67px]"
  // }
  // {
  //   id: meetingModalEnum.LINK,
  //   icon: ZUicons.link,
  //   task: "Get A Meeting Link",
  //   description: "For instant meeting",
  //   backgroundColor: "bg-bluebg",
  //   buttonSyle:
  //     "w-12 h-12 rounded-lg border-[0.5px] border-[#5ca0f3] flex items-center justify-center bg-gradient-to-r from-[#2E87F5] to-[#509cf9]"
  // }
];
