interface ImageData {
  Body: {
    data: Uint8Array;
  };
}
const convertImage = (image: any) => {
  const imageData = image as ImageData;
  if (!imageData?.Body?.data) {
    if (image?.imageUrl) return image?.imageUrl;
    else return null;
  }
  const byteArray = new Uint8Array(imageData?.Body?.data);
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  return imageUrl;
};
export default convertImage;
