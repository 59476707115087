import { PasswordChange, passwordResetResponse } from "types";
import ChangePasswordView from "./ChangePasswordView";
import { updatePassword } from "services/settings.service";
import { useAppSelector } from "hooks";
import { toast } from "react-toastify";
const ChangePasswordContainer = () => {
  const { user } = useAppSelector((state) => state.auth);
  const change = async (details: PasswordChange) => {
    const { currentPassword, newPassword, confirmPassword } = details;
    try {
      const { MESSAGE, STATUS, DATA } = (await updatePassword(
        user?.user?._id, // userId
        currentPassword,
        newPassword,
        confirmPassword
      )) as passwordResetResponse;
      if (STATUS === "SUCCESS" && DATA !== null) {
        toast.success(MESSAGE);
      }
    } catch (error) {}
  };

  return <ChangePasswordView change={change} />;
};

export default ChangePasswordContainer;
