import { ZUimages } from "assets";
import { Link } from "react-router-dom";

const Integrations = () => {
  return (
    <div>
      <div className=" flex flex-col gap-6 bg-[#C7E8D2] w-full h-full text-center py-20 px-5">
        <p className="md:text-[62px] text-[24px] font-bold leading-[60px] ">
          Make the most of your <br /> events
        </p>
        <p className="md:text-[20px] text-lg font-normal opacity-70">
          Host professional webinars and live stream events to <br /> multiple social platforms.
          Experience the best HD quality, <br /> engage your audience with interactive tools, and
          get 24/7 <br /> support.
        </p>
        <Link to="" className="underline underline-offset-8 text-[#07AA3D] text-lg font-normal">
          Explore All Apps
        </Link>
        <img src={ZUimages.socialIcons} alt="" className="mx-auto px-4"></img>
      </div>
    </div>
  );
};

export default Integrations;
