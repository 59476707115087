import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "configs";
import { IMAGE_TAG, NEW_MEETING_TAG, USER_PROFILE_TAG, ZUMARIDI_USER_DATA } from "./CONSTANTS";
import type { RootState } from "redux/store";
import { LOGIN } from "routes/CONSTANTS";

const baseQuery = fetchBaseQuery({
  baseUrl: `${env.API_BASE_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth?.user?.accessToken;
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  }
});

const baseQueryWithLogout = async (
  args: Parameters<typeof baseQuery>[0],
  api: Parameters<typeof baseQuery>[1],
  extraOptions: any
) => {
  const result = await baseQuery(args, api, extraOptions);
  // Check if the response status is 401 (Unauthorized)
  if (result.error && result.error.status === 401) {
    localStorage.removeItem(ZUMARIDI_USER_DATA);
    window.location.replace(LOGIN);
    // void AuthService.logout();
    // Perform logout logic here
    // For example, dispatch an action to clear user data and navigate to the login page
    // const { dispatch } = api;
    // void dispatch(logout);
    //   .unwrap()
    //   .then(() => window.location.replace(LOGIN))
    //   .catch((err) => console.log(err));
  }

  return result;
};

export const baseMeetingApi = createApi({
  reducerPath: "meetingApi",
  baseQuery: baseQueryWithLogout,
  tagTypes: [NEW_MEETING_TAG],
  endpoints: () => ({})
});
export const baseUserApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  tagTypes: [USER_PROFILE_TAG],
  endpoints: () => ({})
});
export const baseImageApi = createApi({
  reducerPath: "imageApi",
  baseQuery,
  tagTypes: [IMAGE_TAG],
  endpoints: () => ({})
});
