import { DASHBOARD } from "routes/CONSTANTS";
import { ZuArrowLeft, ZuCopy, ZuShare, ZuVideo, ZuCheckCircle, ZuAngleUp } from "components";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { CenterLoader } from "components/widgets";
import { ShareInvite } from "./ShareInvite";

interface StartMeetingProps {
  handleCreateNewMeeting: () => void;
  isLoading: boolean;
  meetingID: string;
  meetingLink: string;
  handleStartMeeting: () => void;
}
const StartMeetingView = ({
  // handleCreateNewMeeting,
  isLoading,
  meetingID,
  meetingLink,
  handleStartMeeting
}: StartMeetingProps) => {
  const copyTextRef = useRef<HTMLParagraphElement>(null);
  const [changeCopyIcon, setChangeCopyIcon] = useState<boolean>(false);
  // const modalDiv = useRef<HTMLDivElement | null>(null);
  const [toggleDropdown, setToggleDropdown] = useState<boolean>(false);

  // copy text to clipboard
  const handleCopy = (): void => {
    if (copyTextRef.current) {
      const textToCopy = copyTextRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setChangeCopyIcon(true);
          setTimeout(() => {
            setChangeCopyIcon(false);
          }, 3000);
        })
        .catch(() => {
          setChangeCopyIcon(false);
        });
    }
  };

  const meetingclass = {
    divStyle:
      "flex justify-around w-full items-center px-2 py-3 rounded-[3px] cursor-pointer md:px-2 md:py-3 md:gap-0 md:justify-around lg:justify-around lg:px-[14px] lg:gap-2 lg:py-4",
    iconStyle: "h-5 w-[1.2rem] md:w-[1.3rem] lg:w-[1.5rem] lg:h-[1.5rem] object-contain",
    typography: "tracking-wider font-light text-[.9rem] lg:text-[1rem]"
  };

  return (
    <article
      className={`w-[100%] md:w-[98%] lg:w-[80%] bg-white h-min mx-auto mt-5 rounded-lg px-5 pt-5 pb-20 my-auto relative`}
    >
      <Link
        to={DASHBOARD}
        className="bg-[rgba(7,170,61,0.1)] rounded-full w-[30px] h-[30px] flex items-center justify-center p-[6px]"
      >
        <ZuArrowLeft className="w-full" />
      </Link>
      <section className={`w-full flex justify-center mt-24`}>
        <div className={`w-[80%] md:w-[70%] flex flex-col items-center text-center lg:w-[48%]`}>
          <p className={`font-bold text-[1.5rem] lg:text-[1.7rem]`}>Start a new meeting</p>
          <p className="text-[0.875rem] mt-[0.8rem] lg:text-[1rem]">
            Lorem ipsum dolor sit amet,tum nunc ultrices. Eget diam hac massa, id aliquet nisi.
          </p>
        </div>
      </section>

      <section className={`w-[80%] mx-auto sm:w-[60%] md:w-[70%] lg:w-[65%] mt-12 relative`}>
        <div
          className={`bg-[#F5F5F5] flex justify-between items-center w-full px-2 py-2 mx-auto flex-wrap`}
        >
          <p ref={copyTextRef} className={`lg:text-[1.1rem]`}>
            {meetingID}
          </p>
          {changeCopyIcon ? (
            <ZuCheckCircle className="min-h-[2rem] w-[26px]" />
          ) : (
            <ZuCopy className="min-h-[2rem] w-5 opacity-70 cursor-pointer" onClick={handleCopy} />
          )}
        </div>
      </section>

      <section
        className={`w-[80%] block text-white mx-auto mt-8 sm:w-[60%] xs:flex xs:justify-between md:flex md:w-[70%] md:gap-2 md:justify-between lg:w-[65%] lg:pl-1 lg:gap-6`}
      >
        <div className="relative md:w-[45%] xs:w-[45%] lg:w-[44%]">
          <div
            className={`${meetingclass.divStyle} bg-[#EF691D] w-full`}
            onClick={() => setToggleDropdown((prev) => !prev)}
          >
            <ZuShare className={meetingclass.iconStyle} />
            <p className={meetingclass.typography}>Share invite</p>
            <ZuAngleUp
              className={
                toggleDropdown ? `rotate-180 transition-transform` : `rotate-0 transition-transform`
              }
            />
          </div>
          {toggleDropdown ? (
            <div className="absolute top-[3.8rem] w-full transiton -z-0">
              {/* top-[3.8rem] */}
              <ShareInvite meetingLink={meetingLink} />
            </div>
          ) : (
            <div className="translate-y-[-20px] opacity-0 hidden">
              <ShareInvite meetingLink={meetingLink} />
            </div>
          )}
        </div>

        <div
          className={`${meetingclass.divStyle} bg-[#07AA3D] mt-7 xs:mt-0 md:mt-0 md:w-[50%] xs:w-[50%] lg:w-[49%]`}
          onClick={() => handleStartMeeting()}
        >
          <ZuVideo className={`${meetingclass.iconStyle}`} />
          <p className={`${meetingclass.typography} `}>Start meeting</p>
        </div>
      </section>
      {isLoading && <CenterLoader />}

      {/* {toggleModal ? (
        <div className="absolute w-full bottom-0 left-0 right-0 h-min bg-black-100 bg-opacity-90 py-4 flex px-3">
          <Modal meetingLink={meetingLink} />
          <ZuClose className="cursor-pointer" onClick={() => setToggleModal(false)} />
        </div>
      ) : (
        <div className="absolute w-full bottom-0 left-0 right-0 h-min bg-black-100 bg-opacity-90 py-4 px-3 translate-y-[300px] hidden">
          <Modal meetingLink={meetingLink} />
          <ZuClose className="cursor-pointer" onClick={() => setToggleModal(false)} />
        </div>
      )} */}
    </article>
  );
};

export default StartMeetingView;
