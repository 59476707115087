import {
  CREATE_NEW_MEETING,
  DELETE_A_MEETING,
  EDIT_MEETING,
  GET_ALL_MEETINGS,
  GET_A_MEETING,
  NEW_MEETING_TAG
} from "./CONSTANTS";
import { baseMeetingApi } from "./api";
import { meetingResponse, ScheduleMeetingValues, EditMeetingValues, DeleteResponse } from "types";

export const meetingApi = baseMeetingApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMeetings: builder.query<meetingResponse, string>({
      query: (email) => `${GET_ALL_MEETINGS}/${email}`,
      providesTags: [NEW_MEETING_TAG]
    }),
    createMeeting: builder.mutation<meetingResponse, ScheduleMeetingValues>({
      query: (event) => ({
        url: `${CREATE_NEW_MEETING}`,
        method: "POST",
        body: event
      }),
      invalidatesTags: [NEW_MEETING_TAG]
    }),
    editMeeting: builder.mutation<
      meetingResponse,
      { event: Partial<EditMeetingValues>; editMeetingId: string }
    >({
      query: ({ event, editMeetingId }) => ({
        url: `${EDIT_MEETING}/${editMeetingId}`,
        method: "PATCH",
        body: event
      }),
      invalidatesTags: [NEW_MEETING_TAG]
    }),
    getAMeeting: builder.query<meetingResponse, string>({
      query: (meetingId) => `${GET_A_MEETING}/${meetingId}`,
      providesTags: [NEW_MEETING_TAG]
    }),
    deleteAMeeting: builder.mutation<DeleteResponse, any>({
      query: (meetingId) => ({
        url: `${DELETE_A_MEETING}/${meetingId}`,
        method: "DELETE"
      }),
      invalidatesTags: [NEW_MEETING_TAG]
    })
  })
});

export const {
  useGetAllMeetingsQuery,
  useCreateMeetingMutation,
  useEditMeetingMutation,
  useGetAMeetingQuery,
  useDeleteAMeetingMutation
} = meetingApi;
