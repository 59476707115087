import { useState } from "react";
import { othersData } from "utils";
import ToggleSwitch from "./OthersToggle";

const OthersVideoSettings = () => {
  const [others, setOthers] = useState(othersData);

  const handleToggleItem = (otherId: number) => {
    const updatedItems = others.map((other) => {
      if (other.id === otherId) {
        const newCheckedState = !other.isChecked;
        console.log(`Item ${other.title} toggled to: ${newCheckedState}`);
        return { ...other, isChecked: newCheckedState };
      }
      return other;
    });
    setOthers(updatedItems);
  };
  return (
    <div className="relative overflow-x-auto">
      <div className="w-full text-left text-md">
        <ul>
          {others.map((other, index) => (
            <li
              key={other.id}
              className={`${
                index % 2 === 0
                  ? "bg-green-500/30 hover:bg-green-500/60"
                  : "bg-white hover:bg-gray-100"
              } group transition shadow-black-100 hover:shadow-lg flex items-center justify-between p-3`}
            >
              <div className="px-1 py-0">
                <span className="hidden sm:inline">{other.title}</span>
                <span className="sm:hidden">{other.title.slice(0, 24).trim()}...</span>
              </div>
              <div className="px-1 py-0">
                <ToggleSwitch
                  isChecked={other.isChecked}
                  onChange={() => handleToggleItem(other.id)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OthersVideoSettings;
