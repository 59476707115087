import { useEffect } from "react";
import { ZUimages } from "assets";
const UnderConstructionView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col lg:max-h-[80vh]  max-h-[55vh]">
      <div className="w-full lg:mt-[-40px] mt-[-10px] my-6">
        <img src={ZUimages.colorfulBg} alt="Product Background" className="w-full" />
      </div>
      <div className="flex flex-col items-center my-4 space-y-2">
        <div className="flex gap-2">
          <h4 className="text-xl lg:text-2xl font-semibold">Coming Soon</h4>
          <div className="">
            <img
              src={ZUimages.rocket}
              alt="rocket"
              className="w-8 h-8 lg:w-12 lg:h-12 object-cover"
            />
          </div>
        </div>
        <p className="text-2xl font-medium">Stay tuned to find out!</p>
      </div>
      <div className="flex justify-end w-full lg:my-8 my-10">
        <img src={ZUimages.dots} alt="Dots" className="w-24 h-24 lg:w-32 lg:h-32 z-10" />
      </div>
    </div>
  );
};

export default UnderConstructionView;
