import { useEffect } from "react";
const TermsView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-5 md:pt-10 pb-40 px-5 md:px-10 lg:px-20 space-y-5 md:space-y-10">
      <div className="space-y-3 md:space-y-5">
        <h2 className="font-bold">Terms and Conditions</h2>
        <p>
          Please read these agreement which sets fourth the terms and conditions for your use of
          Zumaridi live streaming and conferencing platform's services. If you continue to browse
          and use this website, you are agreeing to comply with and be bound by the following terms
          and conditions of use, which together with our privacy policy govern Zumaridi's
          relationship with you in relation to this website. This website uses cookies to monitor
          browsing preferences. Neither we nor any third parties provide any warranty or guarantee
          as to the accuracy, timeliness, performance, completeness or suitability of the
          information and materials found or offered on this website for any particular purpose. You
          acknowledge that such information and materials may contain inaccuracies or errors and we
          expressly exclude liability for any such inaccuracies or errors to the fullest extent
          permitted by law. Your use of any information or materials on this website is entirely at
          your own risk, for which we shall not be liable. It shall be your own responsibility to
          ensure that any products, services or information available through this website meet your
          specific requirements
        </p>
      </div>

      <div className="space-y-3 md:space-y-5">
        <h4 className="font-bold">Intended Use of Website</h4>
        <p>
          Zumaridi live streaming and conferencing platform can be used for team meetings, webinars,
          product demos, job interviews, and more. The main purpose of video conferencing is to help
          people connect, regardless of where they're located. The content of the pages of this
          website is for your general information and use only. It is subject to change without
          notice. You may only use the Services pursuant to the terms of this Agreement. You are
          solely responsible for Your and Your End Users’ use of the Services and shall abide by,
          and ensure compliance with, all Laws in connection with Your and each End User’s use of
          the Services, including but not limited to Laws related to recording, intellectual
          property, privacy and export control. Use of the Services is void where prohibited.
        </p>
      </div>

      <div className="space-y-3 md:space-y-5">
        <h4 className="font-bold">User Registration</h4>
        <p>
          By registering and using the Zumaridi platform, you acknowledge that you have read,
          understood, and agreed to these Terms and Conditions. If you do not agree to these Terms,
          you may not use the Service. You may be required to provide information about yourself to
          register for and to access or use the Services and Software. You represent and warrant
          that any such information, including Customer Data, is and will remain accurate and
          complete, and that Zumaridi has no liability whatsoever for errors and omissions in your
          Customer Data. You may also be asked to choose a username and password to access or use
          the Services and Software. We may reject, or require that you change, any such username or
          password, in our sole discretion
        </p>
      </div>

      <div className="space-y-3 md:space-y-5">
        <h4 className="font-bold">Registered Account Obligations</h4>
        <p>
          You shall provide all user information necessary to enable Zumaridi to create user
          profiles for each user you wish to provide access to the Services. You are entirely
          responsible for maintaining the security of your username and password, and you agree not
          to disclose or make your username or password accessible to any third party. You are
          solely responsible for compliance with all Laws and regulations pertaining to the Customer
          Content, including Laws requiring you to obtain the consent of a third party to use,
          license or generate Customer Content and to provide appropriate notices of third party
          rights. Zumaridi may delete any Customer Content, at any time without notice to you if
          Zumaridi becomes aware that it violates any provision of this Agreement or any applicable
          Laws. You retain all ownership rights in your Customer Content subject to any license or
          other rights granted herei
        </p>
      </div>

      <div className="space-y-3 md:space-y-5">
        <h4 className="font-bold">Content use Limitations</h4>
        <p>
          This website contains material which is owned by or licensed to us. This material
          includes, but is not limited to, the design, layout, look, appearance and graphics.
          Reproduction is prohibited other than in accordance with the copyright notice, which forms
          part of these terms and conditions. Unauthorised use of this website may give rise to a
          claim for damages and/or be a criminal offence. From time to time this website may also
          include links to other websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the website(s). We have no
          responsibility for the content of the linked website(s). Unless otherwise indicated, the
          Site is our proprietary property and all source code, databases, functionality, software,
          website designs, audio, video, text, photographs, and graphics on the Site (collectively,
          the “Content”) and the trademarks, service marks, and logos contained therein (the
          “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright
          and trademark laws and various other intellectual property rights and unfair competition
          laws, foreign jurisdictions, and international conventions. All trade marks reproduced in
          this website which are not the property of, or licensed to, the operator are acknowledged
          on the website.
        </p>
      </div>

      <div className="space-y-3 md:space-y-5">
        <h4 className="font-bold">Prospective Investor Account</h4>
        <p>
          Zumaridi undertakes to provide Investors with a platform for Investors to receive
          information and obtain access to investment opportunities. The following terms binds the
          investor’s agreement;
        </p>
        <ul className="list-disc space-y-1 ml-8">
          <li>
            The Investor agrees and undertakes that he/she shall promptly give all information or
            documentation which Zumaridi may request from the Investor, whether jointly or
            severally, from time to time in order to perform the Services and / or to comply with
            any applicable laws and regulations.
          </li>
          <li>
            The Investor agrees and undertakes that he/she will promptly inform Zumaridi should
            he/she become aware of any changes or updates to any information or documentation
            previously provided to Zumaridi.{" "}
          </li>
          <li>That he/she shall, at all times, act with transparency and good faith.</li>
          <li>
            The Investor shall not use the Website, the Account or the Services offered by Zumaridi
            for the purposes of his/her own commercial or professional activities unless the prior
            written consent of Zumaridi has been obtained.
          </li>
          <li>
            The Investor undertakes to immediately inform Zumaridi of any actual or potential
            conflict of interest which may arise in relation to a particular investment.
          </li>
          <li>
            The Investor undertakes, in respect of Confidential Information which may be disclosed
            to it, to treat and keep such Confidential Information secret and confidential. To
            protect such Confidential Information, whether in storage or in use, with the same
            degree of care as the Investor uses to protect his/her own confidential information
            against public disclosure.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsView;
