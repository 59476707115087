export const darkTheme = [{ id: 1, title: "Dark Theme", isChecked: false }];

export const othersData = [
  { id: 1, title: "Show my connection time", isChecked: false },
  { id: 2, title: "Auto-Save recordings", isChecked: false },
  { id: 3, title: "Informs you when someone leaves the meeting", isChecked: false },
  { id: 4, title: "Dark Theme", isChecked: false },
  { id: 5, title: "Switch off my video when joining", isChecked: false },
  { id: 6, title: "Mute my microphone when joining", isChecked: false },
  { id: 7, title: "Show non-video participants", isChecked: false },
  { id: 8, title: "Use keyboard shortcuts", isChecked: false },
  { id: 9, title: "Informs you when someone join the meeting", isChecked: false }
];

export const subTitle = [{ id: 1, title: "Subtitle text language", isChecked: false }];
