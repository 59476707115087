const IMG_PATH = "/img";

const LOGO = `${IMG_PATH}/logo`;
const USERS = `${IMG_PATH}/users`;
const BACKGROUND = `${IMG_PATH}/backgrounds`;
const Polygon = `${IMG_PATH}/polygon`;

export const user1 = `${USERS}/user1.png`;
export const user2 = `${USERS}/user2.png`;
export const user3 = `${USERS}/user3.png`;
export const circularUser1 = `${USERS}/user1_cropped.png`;
export const circularUser2 = `${USERS}/user2_cropped.png`;
export const circularUser3 = `${USERS}/user3_cropped.png`;
export const aboutConference = `${USERS}/conference_preview.png`;
export const youngManOnline = `${USERS}/young_man_online.png`;
export const star = `${USERS}/star.png`;
export const userimg1 = `${USERS}/userimg-1.png`;
export const userimg2 = `${USERS}/userimg-2.png`;
export const userimg3 = `${USERS}/userimg-3.png`;
export const userimg4 = `${USERS}/userimg-4.png`;
export const user = `${USERS}/user.png`;
export const logoutimg = `${USERS}/logoutimg.png`;
export const blackBusinessman = `${USERS}/blackbusinessman.png`;
export const blackBusinessmanSvg = `${USERS}/blackbusinessman.svg`;

export const logo = `${LOGO}/logo.png`;
export const logoIcon = `${LOGO}/logo_icon.png`;
export const ahm = `${LOGO}/AHM.png`;
export const usaf = `${LOGO}/USAF.png`;
export const bmhm = `${LOGO}/BMHM.png`;
export const mwata = `${LOGO}/MWATA.png`;
export const sakaivms = `${LOGO}/Sa Kai VMS.png`;
export const swWestPapua = `${LOGO}/SW WEST PAPUA.png`;

export const authBackground = `${BACKGROUND}/auth.gif`;
export const heroBackground = `${BACKGROUND}/hero.png`;
export const productBackground = `${BACKGROUND}/products-top-icons.webp`;
export const productDots = `${BACKGROUND}/products-dots.png`;
export const productDemo = `${BACKGROUND}/products-watch-demo.webp`;
export const productHd = `${BACKGROUND}/products-hd-video.webp`;
export const productBlueFrame = `${BACKGROUND}/blue-frame.png`;
export const productCalendar = `${BACKGROUND}/products-calendar.webp`;
// export const contactBackground = `${BACKGROUND}/Contact.png`;

export const youngManVector = `${BACKGROUND}/youngman_vector.png`;
export const youngManWithVector = `${BACKGROUND}/youngman_vector_merged.png`;
export const purpleElipse = `${BACKGROUND}/purple_elipse.png`;
export const yellowElipse = `${BACKGROUND}/yellow_elipse.png`;
export const greenElipse = `${BACKGROUND}/green_elipse.png`;
export const socialIcons = `${BACKGROUND}/social_elipse.png`;
export const socialIconsBackground = `${BACKGROUND}/BG.png`;
export const elipseAndLine = `${BACKGROUND}/elipse_line.png`;
export const lastRowBackground = `${BACKGROUND}/last_row_bg.png`;
export const aboutBackground = `${BACKGROUND}/about-bg5.png`;
export const aboutValue = `${BACKGROUND}/about_value.png`;
export const userbg = `${BACKGROUND}/userbg.png`;
export const InnBg = `${BACKGROUND}/Inn.png`;

const SVG_PATH = "/svg";

export const dots = `${SVG_PATH}/dots.svg`;
export const checkcircle = `${SVG_PATH}/checkcircle.svg`;
export const bell = `${SVG_PATH}/bell.svg`;
export const search = `${SVG_PATH}/search.svg`;
export const calendar = `${SVG_PATH}/calender.svg`;
export const share = `${SVG_PATH}/share.svg`;
export const broadcast = `${SVG_PATH}/broadcast.svg`;
export const location = `${SVG_PATH}/location.svg`;
export const envelope = `${SVG_PATH}/envelope.svg`;
export const logout = `${SVG_PATH}/logout.svg`;
export const send = `${SVG_PATH}/send.svg`;
export const zuCoins = `${SVG_PATH}/coins.svg`;
export const zuChatDots = `${SVG_PATH}/chats-dots.svg`;
export const zuMoneyBill = `${SVG_PATH}/money-bill.svg`;
export const zuSettings = `${SVG_PATH}/settings.svg`;
export const zuYouTube = `${SVG_PATH}/youtube.svg`;
export const zuTwitter = `${SVG_PATH}/twitter.svg`;
export const zuLinkedin = `${SVG_PATH}/linkedin.svg`;
export const zuFacebook = `${SVG_PATH}/facebook.svg`;
export const zuTool = `${SVG_PATH}/tool.svg`;
export const zuInstagram = `${SVG_PATH}/instagram.svg`;
export const zuPlaceMarker = `${SVG_PATH}/placeMarker.svg`;
export const zuPhoneCall = `${SVG_PATH}/phoneCall.svg`;
export const zuMailBox = `${SVG_PATH}/mailbox.svg`;
export const ZuVideo = `${SVG_PATH}/video.svg`;
export const ZuPlus = `${SVG_PATH}/plus.svg`;
export const close = `${SVG_PATH}/close.svg`;
export const ZuCalendarPlus = `${SVG_PATH}/calendarplus.svg`;
export const ZuLink = `${SVG_PATH}/link.svg`;
export const ZuPlay = `${SVG_PATH}/play.svg`;
export const ZuRoundFacebook = `${SVG_PATH}/zu_round_facebook.svg`;
export const ZuRoundLinkedin = `${SVG_PATH}/zu_round_linkedin.svg`;
export const ZuRoundPintrest = `${SVG_PATH}/zu_round_pintrest.svg`;
export const ZuRoundSnapchat = `${SVG_PATH}/zu_round_snapchat.svg`;
export const ZuRoundTelegram = `${SVG_PATH}/zu_round_telegram.svg`;
export const ZuRoundTicktock = `${SVG_PATH}/zu_round_ticktock.svg`;
export const ZuRoundTwitter = `${SVG_PATH}/zu_round_twitter.svg`;
export const ZuRoundWhatsapp = `${SVG_PATH}/zu_round_whatsapp.svg`;
export const ZuRoundYoutube = `${SVG_PATH}/zu_round_youtube.svg`;
export const ContactUs = `${SVG_PATH}/contact-us.svg`;
export const colorfulBg = `${SVG_PATH}/colorful.svg`;
export const rocket = `${SVG_PATH}/rocket-launch.svg`;

export const firstPolygon = `${Polygon}/Polygon1.png`;
export const polyUser = `${Polygon}/user.png`;
export const arrow = `${Polygon}/arrow.png`;
export const secondPolygon = `${Polygon}/Polygon2.png`;
export const briefcase = `${Polygon}/case.png`;
export const backarr = `${Polygon}/backarr.png`;
export const allbox = `${Polygon}/allbox.png`;
export const gmail = `${Polygon}/gmail.png`;
export const micro = `${Polygon}/micro.png`;
export const link = `${Polygon}/link.png`;
export const truck = `${Polygon}/truck.png`;
export const ingenuity = `${Polygon}/ingenuity.png`;
export const teamwork = `${Polygon}/teamwork.png`;
export const aboutTop = `${Polygon}/about_top.png`;
export const aboutBottom = `${Polygon}/about_bottom.png`;
export const img404 = `${Polygon}/404.png`;
