import { ZUimages } from "assets";
import { ZuArcheryTarget, ZuAthletics, ZuMoneyCoins } from "components/icons";
const AcheiveMore = () => {
  return (
    <div className="py-20">
      <div className="flex md:flex-row flex-col md:gap-20 gap-8 md:justify-start justify-center items-center">
        <div className="flex md:gap-56 gap-4">
          <img alt="" src={ZUimages.dots} className="md:m-0 -ml-24" />

          <div className="md:pl-0 pl-4 pr-5">
            <h4 className="md:text-[45px] text-[30px] font-normal md:leading-[50px] leading-[20px] md:w-[530px] w-full">
              Supports 67 African
              <br /> languages... and counting
            </h4>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:gap-0 gap-24 justify-center items-center pl-20 pr-5 md:px-20 pt-8 ">
        <div className="w-[390px] pl-8">
          <div>
            <div className="relative w-16 h-16 rounded-full bg-[#FFD166]">
              <ZuAthletics className="w-14 h-14 absolute -bottom-3 -right-3" />
            </div>
          </div>
          <p className="font-bold pt-8 text-[21px]">Secure</p>
          <p className="opacity-70 pt-3 md:text-[21px] text-lg">
            Guaranteed data protection with <br /> end-to-end encryption
          </p>
        </div>
        <div className="flex-col w-[390px] pl-8">
          <div>
            <div className="relative w-16 h-16 rounded-full bg-[#96F7D2]">
              <ZuArcheryTarget className="w-14 h-14 absolute -bottom-3 -right-3" />
            </div>
          </div>
          <p className="font-bold pt-8 text-[21px]">Flexible</p>
          <p className="opacity-70 pt-3 md:text-[21px] text-lg">
            Perfect for meetings <br /> of all sizes
          </p>
        </div>
        <div className="flex-col w-[390px] pl-8">
          <div>
            <div className="relative w-16 h-16 rounded-full bg-[#A9D2FF]">
              <ZuMoneyCoins className="w-14 h-14 absolute -bottom-3 -right-3" />
            </div>
          </div>
          <p className="font-bold pt-8 text-[21px]">Seamless</p>
          <p className="opacity-70 pt-3 md:text-[21px] text-lg">
            Easy to use with crystal clear <br /> quality
          </p>
        </div>
      </div>
    </div>
  );
};

export default AcheiveMore;
