import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
const SvgZuZumaridiLogo = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      width={size ?? "169"}
      height={size ?? "22"}
      viewBox="0 0 169 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M3.86056 18.9919L9.98056 0.991943H1.91656C2.13256 1.49594 2.49256 2.35994 2.63656 2.50394C2.78056 2.79194 3.06856 3.07994 3.78856 3.07994H6.16456C6.52456 3.07994 6.66856 3.29594 6.59656 3.58394L0.476562 21.6559H8.18056C8.82856 21.6559 9.18856 21.3679 9.33256 21.0799L9.98056 19.5679H4.22056C3.93256 19.5679 3.78856 19.3519 3.86056 18.9919Z"
        fill="#041723"
      />
      <path
        d="M28.8458 18.7759C28.8458 19.4239 28.3418 19.9279 27.6938 19.9279H27.3338C26.6858 19.9279 26.2538 19.4239 26.2538 18.7759V0.919922H24.7418C24.3818 0.919922 24.1658 1.20792 24.0938 1.49592V19.1359C24.0938 20.5759 25.2458 21.6559 26.7578 21.6559H28.4138C29.7818 21.6559 30.9338 20.5759 30.9338 19.1359V0.919922H28.7738V18.7759H28.8458Z"
        fill="#041723"
      />
      <path
        d="M52.3199 1.71194L50.0159 13.5919L47.5679 1.78394C47.5679 1.78394 47.4239 0.991943 46.6319 0.991943H45.3359V21.6559H47.4239V10.9999L48.6479 16.3999C48.7919 16.8319 49.0079 18.0559 50.2319 18.0559H50.8799L52.4639 10.9279V21.5839H54.5519V0.991943H53.2559C52.5359 0.991943 52.3919 1.63994 52.3199 1.71194Z"
        fill="#041723"
      />
      <path
        d="M72.4043 0.991943H71.2523L68.1562 21.6559H70.4602L72.3323 7.39994L74.2043 21.6559H76.5082L73.5563 2.07194C73.4123 1.56794 73.2683 0.991943 72.4043 0.991943Z"
        fill="#041723"
      />
      <path
        d="M97.8941 9.99194V3.58394C97.8941 2.14394 96.7421 0.991943 95.3021 0.991943H91.4141V21.6559H93.5741V12.7999H94.7261C95.3021 12.7999 95.8061 13.1599 95.8061 14.0959V21.6559H97.9661V13.7359C97.9661 13.7359 98.0381 12.2239 97.1741 11.7919C98.0381 11.5759 97.8941 9.99194 97.8941 9.99194ZM95.8061 9.55994C95.8061 10.4959 95.3021 10.7119 94.7261 10.7119H93.5741V2.93594H94.7261C95.3021 2.93594 95.8061 3.22394 95.8061 4.01594V9.55994Z"
        fill="#041723"
      />
      <path
        d="M140.159 0.991943H136.055V21.6559H140.159C141.599 21.6559 142.751 20.5039 142.823 19.0639V3.58394C142.751 2.14394 141.599 0.991943 140.159 0.991943ZM140.663 18.4879C140.663 19.2799 140.159 19.5679 139.511 19.5679H138.143V3.07994H139.511C140.087 3.07994 140.663 3.36794 140.663 4.15994V18.4879Z"
        fill="#041723"
      />
      <path
        d="M122.229 1.13599H111.789C111.213 1.13599 110.781 1.56799 110.781 2.14399C110.781 2.71999 111.213 3.15199 111.789 3.15199H122.229C122.805 3.15199 123.237 2.71999 123.237 2.14399C123.237 1.56799 122.805 1.13599 122.229 1.13599Z"
        fill="#041723"
      />
      <path
        d="M121.727 4.73578C121.727 4.15978 121.295 3.72778 120.719 3.72778H113.375C112.799 3.72778 112.367 4.15978 112.367 4.73578C112.367 5.31178 112.799 5.74378 113.375 5.74378H120.719C121.295 5.74378 121.727 5.31178 121.727 4.73578Z"
        fill="#041723"
      />
      <path
        d="M119.063 6.39185H115.031C114.455 6.39185 114.023 6.82385 114.023 7.39985C114.023 7.97585 114.455 8.40785 115.031 8.40785H119.063C119.639 8.40785 120.071 7.97585 120.071 7.39985C120.071 6.82385 119.639 6.39185 119.063 6.39185Z"
        fill="#041723"
      />
      <path
        d="M116.539 10.9999H117.619C118.195 10.9999 118.627 10.5679 118.627 9.99189C118.627 9.41589 118.195 8.98389 117.619 8.98389H116.539C115.963 8.98389 115.531 9.41589 115.531 9.99189C115.531 10.5679 115.963 10.9999 116.539 10.9999Z"
        fill="#041723"
      />
      <path
        d="M122.229 19.4958H111.789C111.213 19.4958 110.781 19.9279 110.781 20.5039C110.781 21.0799 111.213 21.5119 111.789 21.5119H122.229C122.805 21.5119 123.237 21.0799 123.237 20.5039C123.237 19.9999 122.805 19.4958 122.229 19.4958Z"
        fill="#041723"
      />
      <path
        d="M113.375 18.9198H120.719C121.295 18.9198 121.727 18.4878 121.727 17.9118C121.727 17.3358 121.295 16.9038 120.719 16.9038H113.375C112.799 16.9038 112.367 17.3358 112.367 17.9118C112.367 18.4878 112.799 18.9198 113.375 18.9198Z"
        fill="#041723"
      />
      <path
        d="M114.023 15.248C114.023 15.824 114.455 16.256 115.031 16.256H119.063C119.639 16.256 120.071 15.824 120.071 15.248C120.071 14.672 119.639 14.24 119.063 14.24H115.031C114.455 14.24 114.023 14.744 114.023 15.248Z"
        fill="#041723"
      />
      <path
        d="M116.539 13.664H117.619C118.195 13.664 118.627 13.2319 118.627 12.6559C118.627 12.0799 118.195 11.6479 117.619 11.6479H116.539C115.963 11.6479 115.531 12.0799 115.531 12.6559C115.531 13.2319 115.963 13.664 116.539 13.664Z"
        fill="#041723"
      />
      <path
        d="M156.571 3.15199H167.011C167.587 3.15199 168.019 2.71999 168.019 2.14399C168.019 1.56799 167.587 1.13599 167.011 1.13599H156.571C155.995 1.13599 155.562 1.56799 155.562 2.14399C155.562 2.64799 155.995 3.15199 156.571 3.15199Z"
        fill="#041723"
      />
      <path
        d="M165.43 3.72778H158.086C157.51 3.72778 157.078 4.15978 157.078 4.73578C157.078 5.31178 157.51 5.74378 158.086 5.74378H165.43C166.006 5.74378 166.438 5.31178 166.438 4.73578C166.438 4.15978 166.006 3.72778 165.43 3.72778Z"
        fill="#041723"
      />
      <path
        d="M164.782 7.39985C164.782 6.82385 164.35 6.39185 163.774 6.39185H159.742C159.166 6.39185 158.734 6.82385 158.734 7.39985C158.734 7.97585 159.166 8.40785 159.742 8.40785H163.774C164.35 8.40785 164.782 7.90385 164.782 7.39985Z"
        fill="#041723"
      />
      <path
        d="M162.26 8.98389H161.18C160.604 8.98389 160.172 9.41589 160.172 9.99189C160.172 10.5679 160.604 10.9999 161.18 10.9999H162.26C162.836 10.9999 163.268 10.5679 163.268 9.99189C163.268 9.41589 162.836 8.98389 162.26 8.98389Z"
        fill="#041723"
      />
      <path
        d="M167.011 19.4958H156.571C155.995 19.4958 155.562 19.9279 155.562 20.5039C155.562 21.0799 155.995 21.5119 156.571 21.5119H167.011C167.587 21.5119 168.019 21.0799 168.019 20.5039C168.019 19.9999 167.515 19.4958 167.011 19.4958Z"
        fill="#041723"
      />
      <path
        d="M157.078 17.9118C157.078 18.4878 157.51 18.9198 158.086 18.9198H165.43C166.006 18.9198 166.438 18.4878 166.438 17.9118C166.438 17.3358 166.006 16.9038 165.43 16.9038H158.086C157.51 16.9038 157.078 17.3358 157.078 17.9118Z"
        fill="#041723"
      />
      <path
        d="M159.742 16.256H163.774C164.35 16.256 164.782 15.824 164.782 15.248C164.782 14.672 164.35 14.24 163.774 14.24H159.742C159.166 14.24 158.734 14.672 158.734 15.248C158.734 15.824 159.166 16.256 159.742 16.256Z"
        fill="#041723"
      />
      <path
        d="M162.26 11.6479H161.18C160.604 11.6479 160.172 12.0799 160.172 12.6559C160.172 13.2319 160.604 13.664 161.18 13.664H162.26C162.836 13.664 163.268 13.2319 163.268 12.6559C163.268 12.0799 162.836 11.6479 162.26 11.6479Z"
        fill="#041723"
      />
    </svg>
  );
};
SvgZuZumaridiLogo.defaultProps = {
  color: "currentColor"
};
export default SvgZuZumaridiLogo;
