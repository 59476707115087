import OthersDarkTheme from "./OthersDarkTheme";
import OthersSubtitle from "./OthersSubtitle";
import OthersVideoSettings from "./OthersVideoSettings";

const OthersView: React.FC = () => {
  return (
    <div className="border-white bg-white rounded-md border-[1px] px-6 ">
      <div className="space-y-12">
        <div>
          <p className="px-3 py-4 font-medium">Dark Theme</p>
          <OthersDarkTheme />
        </div>

        <div>
          <p className="px-3 py-3 font-medium">Video Settings</p>
          <OthersVideoSettings />
          <OthersSubtitle />
        </div>
      </div>
    </div>
  );
};

export default OthersView;
