// import { useEffect } from "react";
import { Button } from "components";
import { ZuClose } from "components/icons";
import * as Yup from "yup";
import { Settings, UserDetails } from "types";
import { Formik, Form, Field } from "formik";
type ModalId = string | null;

interface ModalProps {
  title?: string;
  onSubmit: Function;
  userDetails: UserDetails;
  setOpenModal: (value: ModalId | null) => void;
  [key: string]: any;
}
function ProfileViewModal({ title, setOpenModal, onSubmit, userDetails }: ModalProps) {
  const ProfileViewSchema = Yup.object().shape({
    firstName: Yup.string().min(5, "Too Short!").max(50, "Too Long!").required(),
    lastName: Yup.string().min(5, "Too Short!").max(50, "Too Long!").required(),
    phoneNumber: Yup.string().required(),
    address: Yup.string().required(),
    zip: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    dateOfBirth: Yup.string().test(
      "not-in-future",
      "Date of Birth cannot be a future date",
      function (value) {
        // If no value is provided, it passes validation (assuming it's a non-required field)
        if (!value) {
          return true;
        }
        // Check if the selected date is greater than today's date
        const selectedDate = new Date(value);
        const currentDate = new Date();
        return selectedDate <= currentDate;
      }
    )
  });
  // initially assiging the values which i am getting from the api, will update once all values are received.
  const initialValues: Settings = {
    firstName: userDetails?.name?.firstName || "",
    lastName: userDetails?.name?.lastName || "",
    phoneNumber: userDetails?.phoneNumber || "",
    address: userDetails?.address || "",
    zip: userDetails?.zip || "",
    city: userDetails?.city || "",
    state: userDetails?.state || "",
    country: userDetails?.country || "",
    dateOfBirth: userDetails?.dateOfBirth || ""
  };
  const handleSubmit = (values: Settings) => {
    const event = {
      id: userDetails?._id,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values?.phoneNumber,
      address: values?.address,
      zip: values?.zip,
      city: values?.city,
      state: values?.state,
      country: values?.country,
      dateOfBirth: values?.dateOfBirth
    };
    onSubmit(event);
    setTimeout(() => {
      setOpenModal(null);
    }, 1000);
    // void create(event);
  };
  return (
    <div className="w-4/5 sm:max-w-4xl mx-auto h-screen fixed inset-0 flex items-center justify-center p-5">
      <div className="relative sm:max-w-md sm:w-3/4 bg-white rounded-lg overflow-hidden z-50">
        <div className="w-full h-14 flex items-center justify-between px-4 py-2 border-b-2 border-b-gray-200 bg-gray-50 rounded-t-lg">
          <p className="text-black font-medium text-[16px] pb-4 pt-4">{title}</p>
          <ZuClose
            size={12}
            onClick={() => {
              setOpenModal(null);
            }}
            className="text-green cursor-pointer"
          />
        </div>
        <div className="px-8 pt-4 sm:w-[600px] w-5/6 min-h-[40vh] max-h-[80vh] overflow-y-auto mb-4 flex-wrap sm:flex-nowrap">
          <div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={ProfileViewSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="p-1 basis-1">
                    <div className="mb-5">
                      <label htmlFor="firstName" className="text-[14px] leading-4 font-medium">
                        First Name
                      </label>
                      <Field
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="block py-2.5 px-2 sm:w-[330px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                      />

                      {errors.firstName && touched.firstName ? (
                        <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                          {errors.firstName}
                        </p>
                      ) : null}
                    </div>
                    <div className="mb-5">
                      <label htmlFor="Last_Name" className="text-[14px] leading-4 font-medium">
                        Last Name
                      </label>
                      <Field
                        required
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="block py-2.5 px-2 sm:w-[330px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                      />
                      {errors.lastName && touched.lastName ? (
                        <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                          {errors.lastName}
                        </p>
                      ) : null}
                    </div>
                    <div className="mb-5">
                      <label htmlFor="Phone Number" className="text-[14px] leading-4 font-medium">
                        Phone Number
                      </label>
                      <Field
                        required
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        autoComplete="on"
                        className="block py-2.5 px-2 sm:w-[330px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                      />
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                          {errors.phoneNumber}
                        </p>
                      ) : null}
                    </div>
                    <div className="mb-5">
                      <label htmlFor="dateOfBirth" className="text-[14px] leading-4 font-medium">
                        Date of Birth
                      </label>
                      <Field
                        required
                        type="date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        autoComplete="on"
                        className="block py-2.5 px-2 sm:w-[330px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                      />
                      {errors.dateOfBirth && touched.dateOfBirth ? (
                        <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                          {errors.dateOfBirth}
                        </p>
                      ) : null}
                    </div>
                    <div className="mb-5">
                      <label htmlFor="country" className="text-[14px] leading-4 font-medium">
                        Country
                      </label>
                      <Field
                        type="text"
                        id="country"
                        name="country"
                        className="block py-2.5 px-2 sm:w-[330px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                      />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="state" className="text-[14px] leading-4 font-medium">
                        State
                      </label>
                      <Field
                        type="text"
                        id="state"
                        name="state"
                        className="block py-2.5 px-2 sm:w-[330px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                      />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="City" className="text-[14px] leading-4 font-medium">
                        City
                      </label>
                      <Field
                        type="text"
                        id="city"
                        name="city"
                        className="block py-2.5 px-2 sm:w-[330px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                      />
                    </div>

                    <div className="flex flex-row items-start columns-2 mb-6 flex-wrap sm:flex-nowrap">
                      <div className="p-1 basis-1">
                        <label htmlFor="Address" className="text-[14px] leading-4 font-medium">
                          Address
                        </label>
                        <span className="text-lg leading-8">
                          <Field
                            required
                            type="text"
                            id="address"
                            name="address"
                            autoComplete="on"
                            className="block py-2.5 px-2 sm:w-[230px] w-52 h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                          />
                        </span>
                        {errors.address && touched.address ? (
                          <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                            {errors.address}
                          </p>
                        ) : null}
                      </div>
                      <div className="p-1 basis-1">
                        <label htmlFor="Zip" className="text-[14px] leading-4 font-medium">
                          Zip Code
                        </label>
                        <span className="text-lg leading-8">
                          <Field
                            required
                            type="text"
                            id="zip"
                            name="zip"
                            autoComplete="on"
                            className="block py-2.5 px-2 sm:w-[90px] w-30   h-[25px] text-gray-900 sm:text-[14px] text-[14px] border border-green-600"
                          />
                        </span>
                        {errors.zip && touched.zip ? (
                          <p className="mb-4 text-[0.8rem] text-alerts-error-color">{errors.zip}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="px-5 sm:pl-52 pl-34 flex flex-row items-start columns-2 ">
                      <div className="">
                        <Button
                          onClick={() => setOpenModal(null)}
                          size="sm"
                          variant="outline"
                          className="w-20 h-12"
                          type="reset"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="pl-2">
                        <Button size="sm" type="submit" className="w-20 h-12">
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div onClick={() => setOpenModal(null)} className="fixed inset-0 bg-black/70 z-10"></div>
    </div>
  );
}
export default ProfileViewModal;
