/* eslint-disable camelcase */
import { GET_USER, UPDATE_USER_PROFILE, UPLOAD_FILE, USER_PROFILE_TAG } from "./CONSTANTS";
import fetch from "./utils/FetchInterceptor";
import { baseUserApi } from "./api";
import { settingsResponse, Settings } from "types";

export const updateProfilePicture = async (formadata: any) => {
  try {
    const data = await fetch({
      url: UPLOAD_FILE,
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formadata
    });
    return data;
  } catch (err) {
    return err;
  }
};

export const userApi = baseUserApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query<settingsResponse, string>({
      providesTags: [USER_PROFILE_TAG],
      query: (id) => `${GET_USER}/${id}`
    }),
    updateUserProfile: builder.mutation<settingsResponse, { id: string; details: Settings }>({
      query: ({ id, details }) => ({
        url: `${UPDATE_USER_PROFILE}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json"
        },
        body: details
      }),
      invalidatesTags: [USER_PROFILE_TAG]
    })
  })
});

export const { useGetUserDetailsQuery, useUpdateUserProfileMutation } = userApi;

export const updatePassword = async (
  userId: string,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
) => {
  try {
    const data = await fetch({
      url: `users/${userId}/password-update`,
      method: "patch",
      headers: {
        "Content-Type": "application/json"
      },
      data: { currentPassword, newPassword, confirmPassword }
    });
    return data;
  } catch (err) {
    return err;
  }
};
