import { Field, Form, Formik } from "formik";
import { changePasswordSchema } from "validations";
import { Button } from "components";
import { useState } from "react";
import { ZuEye, ZuLockPassword } from "components/icons";
import { PasswordChange } from "types";
import { PasswordChangeModal } from "components/modules/modals";
interface Props {
  change: Function;
}
interface ChangePasswordValues {
  currentPassword: string;
  confirmPassword: string;
  newPassword: string;
}

const ChangePasswordView = ({ change }: Props) => {
  const formikInitialValues: PasswordChange = {
    currentPassword: "",
    confirmPassword: "",
    newPassword: ""
  };

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(formikInitialValues);

  /*
    TODO:::
    Instead of three separate functions of revealing the passwords we can have one function
    taking an argument from the input id/name and returning the it's passwords
  */
  const togglePassword1 = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const togglePassword2 = () => {
    setShowNewPassword(!showNewPassword);
  };
  const togglePassword3 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = (values: ChangePasswordValues) => {
    setFormValues(values);
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center mb-10">
        <ZuLockPassword />
        <h6 className="font-bold text-center">Change your password</h6>
      </div>

      <Formik
        initialValues={formikInitialValues}
        onSubmit={onSubmit}
        validationSchema={changePasswordSchema}
      >
        {({ errors, touched, resetForm }) => (
          <Form className="flex flex-col gap-4 py-4">
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col w-full md:w-[1015px] md:max-xl:w-[80%] md:justify-center items-center bg-white rounded-md p-4 mb-10 shadow-md md:flex-row md:items-start md:columns-2 md:flex-no-wrap">
                <div className="relative flex justify-center mb-10">
                  <div className="relative flex flex-col mb-3">
                    <label
                      htmlFor="currentPassword"
                      className="text-green text-[12px] mb-2 mt-2 pl-3"
                    >
                      Current Password
                    </label>
                    <div className="flex text-black text-[18px] relative w-full mb-3 pl-3">
                      <Field
                        type={showCurrentPassword ? "text" : "password"}
                        id="currentPassword"
                        name="currentPassword"
                        autoComplete="on"
                        className="block py-2.5 px-2 w-full md:w-[975px] md:max-xl:w-[100%] text-gray-900 rounded-md h-[52px] border-2 border-green-600"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                        <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200" onClick={togglePassword1}>
                          {<ZuEye />}
                        </p>
                      </div>
                    </div>
                    {errors.currentPassword && touched.currentPassword ? (
                      <p className="mb-4 text-[0.8rem] text-alerts-error-color pl-3">
                        {errors.currentPassword}
                      </p>
                    ) : null}
                    <label htmlFor="currentPassword" className="text-green text-[12px] mb-1 pl-3">
                      New Password
                    </label>
                    <div className="text-black text-[18px] relative w-full mb-3 pl-3">
                      <Field
                        type={showNewPassword ? "text" : "password"}
                        id="newPassword"
                        name="newPassword"
                        className="block py-2.5 px-2 w-full md:w-[975px] md:max-xl:w-[100%]  text-gray-900 rounded-md h-[52px] border-2 border-green-600"
                      />

                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                        <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200" onClick={togglePassword2}>
                          {<ZuEye />}
                        </p>
                      </div>
                    </div>
                    {errors.newPassword && touched.newPassword ? (
                      <p className="mb-4 text-[0.8rem] text-alerts-error-color pl-3">
                        {errors.newPassword}
                      </p>
                    ) : null}
                    <label htmlFor="currentPassword" className="text-green text-[12px] mb-1 pl-3">
                      Confirm Password
                    </label>
                    <div className="text-black text-[18px] relative w-full pl-3 mb-1">
                      <Field
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        className="block py-2.5 px-2 w-full md:w-[975px] md:max-xl:w-[100%]  text-gray-900 rounded-md h-[52px] border-2 border-green-600"
                      />

                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                        <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200" onClick={togglePassword3}>
                          {<ZuEye />}
                        </p>
                      </div>
                    </div>
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <p className="mb-1 text-[0.8rem] text-alerts-error-color pl-3">
                        {errors.confirmPassword}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center">
              <div className="w-full md:w-[1015px] md:max-xl:w-[100%] flex flex-col items-end justify-end">
                <div className="mr-78">
                  <Button
                    size="sm"
                    variant="outline"
                    className="mt-5"
                    style={{ marginRight: "50px" }}
                    type="reset"
                    onClick={() => resetForm}
                  >
                    Cancel
                  </Button>
                  <Button size="sm" className="mt-5 bg-green" type="submit">
                    Change Password
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {
        <div className="">
          <PasswordChangeModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={() => {
              setIsModalOpen(false);
              change(formValues);
            }}
          />
        </div>
      }
    </div>
  );
};

export default ChangePasswordView;
