/* eslint-disable @typescript-eslint/no-misused-promises */

import { Dashboard } from "components";
import ScheduleView from "./ScheduleView";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { ScheduleMeetingValues } from "types";
import {
  useCreateMeetingMutation,
  useEditMeetingMutation,
  useGetAMeetingQuery,
  useDeleteAMeetingMutation
} from "services/meetings.service";
import MeetingDetailsModal from "components/modules/modals/MeetingDetailsModal";

export const ScheduleContainer = () => {
  const [loading, setLoading] = useState(false);
  const [createMeeting, result] = useCreateMeetingMutation();
  const [editMeeting, response] = useEditMeetingMutation();
  const [deleteAMeeting, deleteResponse] = useDeleteAMeetingMutation();
  const [meetingDetailsModal, setMeetingDetailsModal] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState<any | null>(null);
  const [edit, setEdit] = useState(false);
  const [meetingId, setMeetingId] = useState<any>("");
  const [editResponse, setEditResponse] = useState<any | null>(null);
  const [deleteAMeetingLoading, setDeleteAMeetingLoading] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const { data, refetch, isError } = useGetAMeetingQuery(meetingId);

  useEffect(() => {
    toast.success(result.data?.MESSAGE);
    toast.error(result.isError && "Something Went Wrong");
    setLoading(result.isLoading);
    if (result.data?.STATUS === "SUCCESS") {
      setMeetingDetailsModal(true);
      setMeetingDetails(result?.originalArgs);
      setMeetingId(result.originalArgs?.meetingId);
    }
    return () => {
      setMeetingDetailsModal(false);
      setMeetingDetails(null);
    };
  }, [result, data, isError]);

  useEffect(() => {
    toast.success(response.data?.MESSAGE);
    toast.error(response.isError && "Something Went Wrong");
    setLoading(response.isLoading);
    if (response.data?.STATUS === "SUCCESS") {
      const details = {
        ...response?.originalArgs?.event,
        meetingId: response?.originalArgs?.editMeetingId
      };
      setMeetingDetails(details);
      setMeetingDetailsModal(true);
      setEditResponse(null);
    }
  }, [response]);

  useEffect(() => {
    toast.success(deleteResponse.data?.MESSAGE);
    toast.error(deleteResponse.isError && "Something went wrong");
    setDeleteAMeetingLoading(deleteResponse.isLoading);
    if (deleteResponse.data?.STATUS === "SUCCESS") {
      setMeetingDetailsModal(false);
    }
  }, [deleteResponse]);

  const create = async (event: ScheduleMeetingValues): Promise<void> => {
    await createMeeting(event);
  };
  const deleteMeeting = async (id: string): Promise<void> => {
    await deleteAMeeting(id);
  };

  useEffect(() => {
    if (isError) toast.error("Something went wrong");
    if (click) {
      setEditResponse(data);
      setMeetingDetailsModal(false);
    }

    return function () {
      setEditResponse(null);
    };
  }, [click]);
  async function handleGetAMeeting(): Promise<void> {
    setClick((prev: boolean) => !prev);
    await refetch();
  }

  return (
    <Dashboard>
      <ScheduleView
        create={create}
        loading={loading}
        edit={edit}
        setEdit={setEdit}
        editMeeting={editMeeting}
        editMeetingId={meetingId}
        editResponse={editResponse}
        setMeetingDetailsModal={setMeetingDetailsModal}
        setMeetingDetails={setMeetingDetails}
      />
      {meetingDetailsModal && (
        <MeetingDetailsModal
          setOpenModal={setMeetingDetailsModal}
          meetingDetails={meetingDetails}
          setEdit={setEdit}
          setEditResponse={setEditResponse}
          getAMeeting={handleGetAMeeting}
          deleteAMeeting={deleteMeeting}
          deleteAMeetingLoading={deleteAMeetingLoading}
        />
      )}
    </Dashboard>
  );
};
