interface ModalProps {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  [key: string]: any;
}

function PasswordChangeModal({ isOpen, onClose, onConfirm }: ModalProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg overflow-hidden w-[400px] h-[200px]">
          <div className="p-8 min-w-[300px] max-w-[700px]">
            <div className="mb-5 text-center">
              <label className="text-14px leading-4 font-medium">
                Are you sure you want to change your Password?
              </label>
            </div>
            <div className="flex justify-center space-x-4">
              <button
                className="w-20 h-8 rounded-md border border-green-500"
                type="reset"
                onClick={() => onClose()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="w-20 h-8 bg-green rounded-md border"
                onClick={() => onConfirm()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black/70 z-10"></div>
    </div>
  );
}

export default PasswordChangeModal;
