import { Dashboard } from "components";
import StartMeetingView from "./StartMeetingView";
import { useCreateMeetingMutation } from "services/meetings.service";
import { useState, useEffect } from "react";
import randomString from "random-string";
import { toast } from "react-toastify";
import env from "configs";

export const StartMeetingContainer = () => {
  const [createMeeting, result] = useCreateMeetingMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [meetingID, setMeetingID] = useState<string>((): string => {
    return randomString({ length: 10 }).toLowerCase();
  });

  const idExists = "Meeting with this ID already exists";

  const handleStartMeeting = (): void => {
    if (result.data?.STATUS === "SUCCESS") {
      window.open(`${env.MEETING_BASE_URL}/${result.originalArgs?.meetingId}`);
    } else if (result.error) {
      const error = result.error;
      if ("data" in error) {
        console.log(result);
        toast.error(idExists);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    handleCreateNewMeeting();
  }, []);

  const handleCreateNewMeeting = (): void => {
    setMeetingID((prev) => prev);
    setIsLoading(true);
    const date = new Date().toISOString();
    const event = {
      meetingId: meetingID,
      title: "Instant Meeting",
      description: "Start an instant meeting",
      date,
      start: date,
      end: date,
      meetingType: "instant",
      recurring: false,
      mute: false,
      camera: false,
      waiting: false,
      share: false,
      record: false,
      guests: []
    };
    createMeeting(event)
      .then(() => {
        setIsLoading(false);
        // console.log(result);
      })
      .catch(() => {
        toast.error("Error creating meeting");
        setIsLoading(false);
      });
  };
  return (
    <Dashboard>
      <StartMeetingView
        handleCreateNewMeeting={handleCreateNewMeeting}
        isLoading={isLoading}
        meetingID={meetingID}
        meetingLink={`${env.MEETING_BASE_URL}/${result.originalArgs?.meetingId}`}
        handleStartMeeting={handleStartMeeting}
      />
    </Dashboard>
  );
};
