import ProfileView from "./ProfileView";
import { Settings, UserDetails } from "types";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { useAppSelector } from "hooks";

import { useGetUserDetailsQuery, useUpdateUserProfileMutation } from "services/settings.service";
import {
  useGetImageQuery,
  useUpdateUserImageMutation,
  useUploadImageMutation
} from "services/images.service";
import convertImage from "utils/convertImage";

function ProfileContainer() {
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useGetUserDetailsQuery(user?.user?._id);
  const [updateUser, result] = useUpdateUserProfileMutation();
  const [updateImage] = useUpdateUserImageMutation();
  const [uploadImage, uploadedImage] = useUploadImageMutation();
  const settingsData = data?.DATA as any as UserDetails;

  let imageUrl: any;

  useEffect(() => {
    toast.success(result.data?.MESSAGE);
    toast.error(result.isError && "Something Went Wrong");
    setLoading(result.isLoading);
  }, [result]);

  const { data: imageQueryData } = useGetImageQuery(settingsData?.profilePhoto, {
    skip: !settingsData?.profilePhoto // Skip the query if profilePhoto doesn't exist
  });
  const image = imageQueryData?.DATA;

  useEffect(() => {
    if (uploadedImage.data?.DATA) {
      const updatedImageId = uploadedImage.data?.DATA;
      const { imageId } = updatedImageId;
      const updatedDetails = {
        ...data?.DATA,
        profilePhoto: imageId
      };
      const details = updatedDetails as Settings;
      void updateUser({ id: user?.user?._id, details });
    }
  }, [uploadedImage]);

  if (image) {
    imageUrl = convertImage(image);
  }

  const pictureUpload = (img: any) => {
    if (!settingsData.profilePhoto) {
      void uploadImage(img);
    } else {
      void updateImage({ id: settingsData?.profilePhoto, image: img });
    }
  };

  const create = (details: Settings) => {
    void updateUser({ id: user?.user?._id, details });
  };

  return (
    <ProfileView
      loading={loading}
      create={create}
      userDetails={settingsData}
      image={imageUrl}
      pictureUpload={pictureUpload}
      isLoading={isLoading}
    />
  );
}

export default ProfileContainer;
