import { configureStore } from "@reduxjs/toolkit";
import reducer from "redux/slices";
import { setupListeners } from "@reduxjs/toolkit/query";
import { meetingApi } from "services/meetings.service";
import { userApi } from "services/settings.service";
import { imageApi } from "services/images.service";

export const store = configureStore({
  reducer,
  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware().concat([meetingApi.middleware, userApi.middleware, imageApi.middleware]),
  devTools: true
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
