import React from "react";

interface ToggleProps {
  isChecked: boolean;
  onChange: () => void;
}

const ToggleSwitch: React.FC<ToggleProps> = ({ isChecked, onChange }) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" className="sr-only peer" checked={isChecked} onChange={onChange} />
      <div
        className={`w-9 h-5 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all ${
          isChecked ? "peer-checked:bg-green-500" : ""
        }`}
      ></div>
      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{}</span>
    </label>
  );
};

export default ToggleSwitch;
