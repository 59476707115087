import { Link } from "react-router-dom";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import NavLink from "./NavLink";
import { ZUimages } from "assets";
import { Drawer } from "components/modules";
import { logout } from "redux/slices/auth.slice";
import { Button, Dropdown, Menu } from "components/widgets";
import { useAppDispatch, useAppSelector, useScroll } from "hooks";
import { ZuFlagUnitedKingdom, ZuDown, ZuLogout, ZuSettings, ZuUser } from "components/icons";
import {
  ABOUT,
  CONTACT,
  HOME,
  LOGIN,
  SIGNUP,
  PRICING,
  PRODUCTS,
  SETTINGS,
  DASHBOARD
} from "routes/CONSTANTS";
import { useGetImageQuery } from "services/images.service";
import convertImage from "utils/convertImage";
import { UserDetails } from "types";
import { useGetUserDetailsQuery } from "services/settings.service";
import { useState } from "react";
// import { Products } from "pages";

interface Props {
  transparent?: boolean;
}

const Navbar = ({ transparent = false }: Props) => {
  const { y } = useScroll();
  const dispatch = useAppDispatch();
  const { user, isLoggedIn } = useAppSelector((state) => state.auth);
  const { data } = useGetUserDetailsQuery(user?.user?._id);
  const settingsData = data?.DATA as any as UserDetails;
  const [open, toggle] = useCycle(false, true);
  const [hoverData, setHoverData] = useState(""); // State for hover data
  const [showTooltip, setShowTooltip] = useState(false); // State for tooltip visibility

  const onHover = (e: any, data: string) => {
    e.preventDefault();
    setHoverData(data);
    setShowTooltip(true);
  };

  const onHoverOver = (e: any) => {
    e.preventDefault();
    setShowTooltip(false);
  };

  const handleLogout = () => {
    void dispatch(logout());
  };
  const { data: imageQueryData } = useGetImageQuery(settingsData?.profilePhoto, {
    skip: !settingsData?.profilePhoto // Skip the query if profilePhoto doesn't exist
  });
  const image = imageQueryData?.DATA;
  let firstname = "";
  let lastname = "";
  let imageUrl: any;

  if (settingsData) {
    firstname = settingsData.name?.firstName;
    lastname = settingsData.name?.lastName;
  } else {
    firstname = user?.user?.name?.firstName;
    lastname = user?.user?.name?.lastName;
  }

  if (image) {
    imageUrl = convertImage(image);
  } else {
    imageUrl = user?.user?.profilePhoto;
  }

  return (
    <motion.div
      initial={false}
      animate={open ? "open" : "closed"}
      className={`${
        transparent && y <= 40 ? "bg-transparent" : "bg-black-100"
      } fixed lg:relative top-0 left-0 right-0 text-white z-50`}
    >
      <div className="relative w-full h-[10vh] px-5 md:px-10 xl:px-20 flex items-center justify-between z-50">
        <Link to={HOME}>
          <img src={ZUimages.logo} alt="logo" />
        </Link>

        <div className="hidden lg:inline-flex items-center space-x-3">
          <NavLink to="/">Home</NavLink>
          <Dropdown
            button={
              <div className="flex items-center gap-2 cursor-pointer">
                Products
                <ZuDown size={10} />
              </div>
            }
          >
            <Link to={PRODUCTS}>
              <p className="text-black font-bold">Video Conferencing</p>
            </Link>
          </Dropdown>
          <NavLink to={CONTACT}>Contact Us</NavLink>
          <NavLink to={PRICING}>Pricing</NavLink>
          <NavLink to={ABOUT}>About Us</NavLink>
        </div>

        <div className="inline-flex items-center space-x-3">
          {!isLoggedIn ? (
            <div className="hidden lg:inline-flex items-center space-x-3">
              <Button to={LOGIN} variant="outline">
                Sign In
              </Button>
              <Button to={SIGNUP}>Create an Account</Button>
            </div>
          ) : (
            <div className="inline-flex items-center space-x-3">
              <p className="hidden lg:block">Welcome {firstname}</p>
              <Dropdown
                button={
                  <Link to={DASHBOARD}>
                    <div className="w-10 h-10 rounded-full overflow-hidden cursor-pointer">
                      <img
                        src={imageUrl || ZUimages.user1}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </Link>
                }
              >
                <>
                  <div className="w-60 flex flex-col items-center p-2">
                    <div className="flex flex-col items-center mb-8 mt-4">
                      <div className="w-14 h-14 rounded-full overflow-hidden cursor-pointer">
                        <img src={imageUrl} alt="" className="w-full h-full object-cover" />
                      </div>
                      <p className="text-black font-semibold text-[1.2rem]">
                        {firstname} {lastname}
                      </p>
                    </div>
                    <div className="w-full">
                      <div className="pb-4 flex flex-col border-b">
                        <Link
                          to={DASHBOARD}
                          className="flex items-center gap-3 cursor-pointer hover:bg-slate-100 p-2 rounded-lg"
                        >
                          <ZuUser color="black" size={15} />
                          <p className="text-black text-[1rem]">My Profile Dashboard</p>
                        </Link>
                        <Link
                          to={SETTINGS}
                          className="flex items-center gap-3 cursor-pointer hover:bg-slate-100 p-2 rounded-lg"
                        >
                          <ZuSettings color="black" size={15} />
                          <p className="text-black text-[1rem]">Settings</p>
                        </Link>
                      </div>
                      <button
                        onClick={handleLogout}
                        className="flex items-center w-full gap-3 mt-4 hover:bg-red-500/20 p-2 rounded-lg"
                      >
                        <ZuLogout className="text-black" />
                        <p className="text-[1rem] text-black">Logout</p>
                      </button>
                    </div>
                  </div>
                </>
              </Dropdown>
            </div>
          )}
          {/* Code with dropdown */}
          {/* <Dropdown
            button={
              <div className="flex items-center gap-2 px-1 py-px border-2  b border-white rounded-lg cursor-pointer">
                <ZuFlagUnitedKingdom size={20} />
@@ -166,7 +168,16 @@ const Navbar = ({ transparent = false }: Props) => {
            }
          >
            <ZuDown size={10} />
            <></>
          </Dropdown>
          </Dropdown> */}

          {/* Code without dropdown */}

          <button>
            <div
              className="flex items-center gap-2 px-1 py-px border-2 border-white rounded-lg cursor-pointer relative"
              onMouseEnter={(e) => onHover(e, "English")}
              onMouseLeave={(e) => onHoverOver(e)}
            >
              <ZuFlagUnitedKingdom size={20} />
              {showTooltip && (
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 bg-black text-white px-2 py-1 rounded-md">
                  <p>{hoverData}</p>
                </div>
              )}
            </div>
          </button>

          <div className="flex items-center lg:hidden">
            <Menu toggle={toggle} />
          </div>
        </div>
      </div>
      <AnimatePresence>{open && <Drawer open={open} />}</AnimatePresence>
    </motion.div>
  );
};

export default Navbar;
