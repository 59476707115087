import * as Yup from "yup";

const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Weak Password. Password must have at least: 1 upper case, 1 digit, 1 special character, Minimum eight in length"
    )
    .max(25, "Password length exceeded")
    .notOneOf(
      [Yup.ref("currentPassword"), null],
      "New password must be different from the current password"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
});
export default changePasswordSchema;
