import { bell, search } from "assets/images";
import { Link } from "react-router-dom";
import { HOME, SETTINGS, DASHBOARD } from "routes/CONSTANTS";
import { logout } from "redux/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "hooks";
import { ZuLogout, ZuSettings, ZuUser, ZuZumaridiLogo } from "components/icons";
import { ZUimages } from "assets";
import { useGetUserDetailsQuery } from "services/settings.service";
import { UserDetails } from "types";
import convertImage from "utils/convertImage";
import { useGetImageQuery } from "services/images.service";

const Header = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { data } = useGetUserDetailsQuery(user?.user?._id);
  const settingsData = data?.DATA as any as UserDetails;
  const handleLogout = () => {
    void dispatch(logout())
      .unwrap()
      .then(() => window.location.replace(HOME))
      .catch((err) => console.log(err));
  };

  const { data: imageQueryData } = useGetImageQuery(settingsData?.profilePhoto, {
    skip: !settingsData?.profilePhoto // Skip the query if profilePhoto doesn't exist
  });
  const image = imageQueryData?.DATA;
  let firstname = "";
  let lastname = "";
  let imageUrl: any;

  if (settingsData) {
    firstname = settingsData.name?.firstName;
    lastname = settingsData.name?.lastName;
  } else {
    firstname = user?.user?.name?.firstName;
    lastname = user?.user?.name?.lastName;
  }

  if (image) {
    imageUrl = convertImage(image);
  } else {
    imageUrl = user?.user?.profilePhoto;
  }

  return (
    <div className="w-full h-[10vh] px-5 md:pr-[65px] md:pl-5 flex items-center justify-between bg-white">
      <Link to={HOME}>
        <div className="mt:0 lg:mt-5">
          <ZuZumaridiLogo />
        </div>
      </Link>

      <div className="flex items-center space-x-3 md:space-x-5 w-[150px] justify-between">
        <div className="p-3 rounded-full shadow-md">
          <img alt="" src={search} />
        </div>
        <div>
          <img alt="" src={bell} />
        </div>
        <div className="group relative">
          {/* <img src={ZUimages.user1} className="rounded-full w-10 h-10 md:w-14 md:h-14" /> */}
          <div className="w-10 h-10 rounded-full overflow-hidden cursor-pointer">
            <img alt="" src={imageUrl || ZUimages.user1} className="w-full h-full object-cover" />
          </div>
          <div className="w-64 bg-white rounded-lg flex-col items-center p-6 drop-shadow-md absolute hidden group-hover:block right-0 z-20">
            <div className="flex flex-col items-center mb-8 mt-4">
              <div className="w-14 h-14 rounded-full overflow-hidden cursor-pointer">
                <img
                  alt=""
                  src={imageUrl || ZUimages.user1}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-black font-semibold text-[1.2rem] text-center">
                {firstname} {lastname}
              </p>
            </div>
            <div className="w-full">
              <div className="pb-4 flex flex-col border-b">
                <Link
                  to={DASHBOARD}
                  className="flex items-center gap-3 cursor-pointer hover:bg-slate-100 p-2 rounded-lg"
                >
                  <ZuUser color="black" size={15} />
                  <p className="text-black text-[1rem]">My Profile Dashboard</p>
                </Link>
                <Link
                  to={SETTINGS}
                  className="flex items-center gap-3 cursor-pointer hover:bg-slate-100 p-2 rounded-lg"
                >
                  <ZuSettings color="black" size={15} />
                  <p className="text-black text-[1rem]">Settings</p>
                </Link>
              </div>
              <button
                onClick={handleLogout}
                className="flex items-center w-full gap-3 mt-4 hover:bg-red-500/20 p-2 rounded-lg"
              >
                <ZuLogout className="text-black" />
                <p className="text-[1rem] text-black">Logout</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
