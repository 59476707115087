import { ZuArrowLeft, Loader } from "components";
import { formatDate, formatTime } from "utils/formatDateAndTime";

import { useNavigate } from "react-router-dom";
interface MeetingDetailsModalProps {
  setOpenModal: (meetingDetailsModal: boolean) => void;
  meetingDetails: any;
  setEdit: (editName: boolean) => void;
  setEditResponse: (editResponse: any) => void;
  getAMeeting: () => void;
  deleteAMeeting: (id: string) => void;
  deleteAMeetingLoading: boolean;
}

const MeetingDetailsModal = ({
  setOpenModal,
  meetingDetails,
  setEdit,
  getAMeeting,
  deleteAMeeting,
  deleteAMeetingLoading
}: MeetingDetailsModalProps) => {
  const navigate = useNavigate();

  const handleEditBtn = async () => {
    setEdit(true);

    void getAMeeting();
  };
  const deleteMeeting = async (id: string) => {
    deleteAMeeting(id);
  };

  return (
    <div
      className="fixed inset-0 bg-black/70 z-50 flex items-center justify-center p-5"
      onClick={() => setOpenModal(false)}
    >
      <div
        className="h-[60vh] w-[90vw] sm:w-[70vw] md:w-[35vw] bg-white py-10 flex flex-col gap-8 rounded-lg px-5 lg:px-10 overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex gap-7 items-center">
          <ZuArrowLeft
            color="#000"
            onClick={() => navigate("/dashboard")}
            className="cursor-pointer"
          />
          <h1 className="text-[21px] font-medium">Meeting Details</h1>
        </div>
        <div className="grid lg:grid-cols-10 gap-2 lg:gap-5 grid-cols-1">
          <h5 className="text-lg font-medium col-span-2">Title</h5>
          <h5 className="text-lg capitalize col-span-8">{meetingDetails?.title}</h5>

          <h5 className="text-lg font-medium col-span-2">Description</h5>
          <h5 className="text-lg capitalize col-span-8">{meetingDetails?.description}</h5>
          <h5 className="text-lg font-medium col-span-2">Date</h5>
          <h5 className="text-lg col-span-8">{formatDate(meetingDetails?.date)}</h5>
          <h5 className="text-lg font-medium col-span-2">Time</h5>
          <h5 className="text-lg col-span-8">
            {formatTime(meetingDetails?.start)} to {formatTime(meetingDetails?.end)}
          </h5>
          <h5 className="text-lg font-medium col-span-2">Guest</h5>
          <div className="col-span-8">
            {meetingDetails?.guests?.map((guest: any, index: number) => (
              <h5 className="text-lg" key={index}>
                {guest}
              </h5>
            )) ?? []}
          </div>
          <h5 className="text-lg font-medium col-span-2">Meeting ID</h5>
          <h5 className="text-lg col-span-8">{meetingDetails?.meetingId}</h5>
          <h5 className="text-lg font-medium col-span-2">Meeting Link</h5>
          <h5 className="text-lg col-span-8 break-all hyphens-auto">
            https://meet.zumaridiafrica.com/{meetingDetails?.meetingId}
          </h5>
        </div>
        <div className="flex gap-10 flex-wrap justify-center sm:justify-start">
          <button className="bg-green py-2  w-[100px] rounded-md text-white">Share</button>
          <button
            className="border text-green w-[100px] border-green py-2 px-8 rounded-md text-white"
            onClick={() => {
              void handleEditBtn();
            }}
          >
            Edit
          </button>
          <button
            className="border text-red w-[100px] flex justify-center items-center border-red py-2 rounded-md text-white"
            onClick={() => {
              void deleteMeeting(meetingDetails?.meetingId);
            }}
          >
            {deleteAMeetingLoading ? <Loader color="red" /> : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default MeetingDetailsModal;
