import { useState, useMemo } from "react";
import { subTitle } from "utils";
import Select from "react-select";
import countryList from "react-select-country-list";
const OthersSubtitle = () => {
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []) as any;

  const changeHandler = (value: any) => {
    console.log(value);
    setValue(value);
  };

  return (
    <div className="relative overflow-x-auto">
      <div className="w-full text-left text-md">
        <ul>
          {subTitle.map((sub, index) => (
            <li
              key={sub.id}
              className={`${
                index % 2 === 0
                  ? "bg-white hover:bg-gray-100"
                  : "bg-green-500/30 hover:bg-green-500/60"
              } group transition shadow-black-100 hover:shadow-lg flex items-center justify-between p-3`}
            >
              <div className="px-1 py-0">
                <span className="hidden sm:inline">{sub.title}</span>
                <span className="sm:hidden">{sub.title.slice(0, 16).trim()}...</span>
              </div>
              <div className="px-1 py-0">
                <div className="max-w-lg mx-auto">
                  <Select options={options} value={value} onChange={changeHandler} />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OthersSubtitle;
