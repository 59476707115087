import { combineReducers } from "@reduxjs/toolkit";
import { meetingApi } from "services/meetings.service";
import { userApi } from "services/settings.service";

import auth from "./auth.slice";
import image from "./image.slice";
import message from "./message.slice";
import organization from "./organization.slice";
// import settings from "./settings.slice";
import { imageApi } from "services/images.service";

const rootReducer = combineReducers({
  auth,
  image,
  message,
  organization,
  // settings,
  [meetingApi.reducerPath]: meetingApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [imageApi.reducerPath]: imageApi.reducer
});

export default rootReducer;
