import { useState } from "react";
import ToggleSwitch from "./OthersToggle";
import { darkTheme } from "utils";

const OthersDarkTheme = () => {
  const [darkT, setDarkT] = useState(darkTheme);

  const handleToggleItem = (darkId: number) => {
    const updatedItems = darkT.map((dark) => {
      if (dark.id === darkId) {
        const newCheckedState = !dark.isChecked;
        console.log(`Item ${dark.title} toggled to: ${newCheckedState}`);
        return { ...dark, isChecked: newCheckedState };
      }
      return dark;
    });

    setDarkT(updatedItems);

    // Re-arrange the settings object according to the ids
    const settingsObject = updatedItems.reduce((settings, item) => {
      settings[item.title] = item.isChecked;
      return settings;
    }, {});
    console.log("Dark Mode Settings Object:", settingsObject);
  };

  return (
    <div className="relative overflow-x-auto">
      <div className="w-full text-left">
        <ul>
          {darkT.map((dark, index) => (
            <li
              key={dark.id}
              className={`${
                index % 2 === 0
                  ? "bg-green-500/30 hover:bg-green-500/60"
                  : "bg-white hover:bg-gray-100"
              } group transition shadow-black-100 hover:shadow-lg flex items-center justify-between p-3`}
            >
              <div className="px-1 py-0">{dark.title}</div>
              <div className="px-1 py-0">
                <ToggleSwitch
                  isChecked={dark.isChecked}
                  onChange={() => handleToggleItem(dark.id)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OthersDarkTheme;
