export const formatTime = (timeString: any): string => {
  const date = new Date(timeString);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export function getCurrentTimeMessage(meeting: any): string {
  const meetingStartTime = new Date(meeting.start);
  const meetingEndTime = new Date(meeting.end);
  const currentDate = new Date();

  const timeDifference = meetingStartTime.getTime() - currentDate.getTime();

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  let message = "";

  if (currentDate > meetingStartTime && currentDate <= meetingEndTime) {
    message = "Meeting has already started.";
  }
  if (currentDate > meetingStartTime && currentDate > meetingEndTime) {
    message = "Meeting has already ended.";
  } else if (daysDifference > 0) {
    message = `Meeting starts in ${daysDifference} day${daysDifference > 1 ? "s" : ""}.`;
  } else if (hoursDifference > 0) {
    message = `Meeting starts in ${hoursDifference} hour${hoursDifference > 1 ? "s" : ""}.`;
  } else if (minutesDifference > 0) {
    message = `Meeting starts in ${minutesDifference} minute${minutesDifference > 1 ? "s" : ""}.`;
  } else {
    message = "Meeting starts now.";
  }
  const formattedMessage = `${formatTime(meeting.start)} - ${formatTime(meeting.end)} | ${message}`;
  return formattedMessage;
}

export default getCurrentTimeMessage;
